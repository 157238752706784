<template>
  <div id="rightBottom">
    <div class="lb-title">残疾人就业收入情况</div>
    <dv-scroll-board class="lb-content" :config="config" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["乡 / 镇", "收入金额"],
        // data: [
        //   ["浙江省淳安县里商乡", "597,405.9"],
        //   ["浙江省淳安县千岛湖镇", "559,305.63"],
        //   ["浙江省淳安县文昌镇", "260,139.6"],
        //   ["浙江省淳安县浪川乡", "246,349.8"],
        //   ["浙江省淳安县瑶山乡", "199,167.36"],
        //   ["浙江省淳安县威坪镇", "190,673.88"],
        //   ["浙江省淳安县枫树岭镇", "145,073.88"],
        //   ["浙江省淳安县大墅镇", "140,513.88"],
        //   ["浙江省淳安县屏门乡", "65,751.84"],
        //   ["浙江省淳安县姜家镇", "64,317.96"],
        //   ["浙江省淳安县左口乡", "55,675.92"],
        //   ["浙江省淳安县王阜乡", "50,160.0"],
        //   ["浙江省淳安县临岐镇", "47,511.84"],
        //   ["浙江省淳安县中洲镇", "35,633.88"],
        //   ["浙江省淳安县梓桐镇", "34,677.96"],
        //   ["浙江省淳安县界首乡", "34,677.96"],
        //   ["浙江省淳安县安阳乡", "30,595.92"],
        //   ["浙江省淳安县汾口镇", "23,755.92"],
        //   ["浙江省淳安县金峰乡", "18,717.96"],
        //   ["浙江省淳安县富文乡", "11,877.96"],
        //   ["浙江省淳安县宋村乡", "9,120.0"],
        // ],
         data: [
          ["延庆区延庆镇", "597,405.9"],
          ["延庆区康庄镇", "559,305.63"],
          ["延庆区八达岭镇", "260,139.6"],
          ["延庆区永宁镇", "246,349.8"],
          ["延庆区旧县镇", "199,167.36"],
          ["延庆区张山营镇", "190,673.88"],
          ["延庆区四海镇", "145,073.88"],
          ["延庆区千家店镇", "140,513.88"],
          ["延庆区沈家营镇", "65,751.84"],
          ["延庆区大榆树镇", "64,317.96"],
          ["延庆区井庄镇", "55,675.92"],
          ["延庆区刘斌堡乡", "50,160.0"],
          ["延庆区大庄科乡", "47,511.84"],
          ["延庆区香营乡", "35,633.88"],
          ["延庆区珍珠泉乡", "34,677.96"],
          // ["浙江省淳安县界首乡", "34,677.96"],
          // ["浙江省淳安县安阳乡", "30,595.92"],
          // ["浙江省淳安县汾口镇", "23,755.92"],
          // ["浙江省淳安县金峰乡", "18,717.96"],
          // ["浙江省淳安县富文乡", "11,877.96"],
          // ["浙江省淳安县宋村乡", "9,120.0"],
        ],
        rowNum: 8, //表格行数
        headerHeight: 50,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [100, 180, 150],
        align: ["center"],
      },
    }
  },
  components: {},
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
$box-width: 100%;
$box-height: 480px;

#rightBottom {
  padding: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .lb-title {
    width: 100%;
    height: 8%;
    // display: flex;
    // align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: rgb(158, 191, 232);
  }
  .lb-content {
    width: 100%;
    height: 92%;
  }
}
</style>
