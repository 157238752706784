<template>
  <div id="centerTop">
    <!-- centerTop -->
    <div class="ct-total">
      <div class="ct-item" v-for="(item, index) in totalCount" :key="index">
        <div>{{ item.name }}</div>
        <div class="num">{{ item.value }}人</div>
      </div>
    </div>
    <div id="mapc" style="width:100%;height:85%"></div>
  </div>
</template>

<script>
// import * as echarts from "echarts"
// import axios from "axios"
// import chunanData from "./json/chunan.json"
import yanqingData from "./json/yanqing.json"

export default {
  data() {
    return {
      totalCount: [
        { name: "公益岗位人数", value: 1150 },
        { name: "职康岗位人数", value: 500 },
        { name: "总计人数", value: 1650 },
      ],
      // map: [
      //   { name: "千岛湖镇", value: ["119.078201", "29.606841", 27] },
      //   { name: "里商乡", value: ["119.001499", "29.489775", 24] },
      //   { name: "文昌镇", value: ["119.205467", "29.775261", 18] },
      //   { name: "瑶山乡", value: ["119.075353", "29.961357", 17] },
      //   { name: "浪川乡", value: ["118.604556", "29.492402", 12] },
      //   { name: "梓桐镇", value: ["118.763103", "29.605679", 12] },
      //   { name: "威坪镇", value: ["118.795694", "29.725313", 11] },
      //   { name: "姜家镇", value: ["118.664924", "29.471265", 10] },
      //   { name: "枫树岭镇", value: ["118.709540", "29.367901", 9] },
      //   { name: "大墅镇", value: ["118.738778", "29.400623", 8] },
      //   { name: "临岐镇", value: ["119.120263", "29.847571", 4] },
      //   { name: "左口乡", value: ["119.076105", "29.697623", 4] },
      //   { name: "中洲镇", value: ["118.487093", "29.438411", 3] },
      //   { name: "屏门乡", value: ["119.039021", "29.864862", 3] },
      //   { name: "王阜乡", value: ["118.918451", "29.835635", 3] },
      //   { name: "安阳乡", value: ["118.831529", "29.420655", 2] },
      //   { name: "汾口镇", value: ["118.558513", "29.427834", 2] },
      //   { name: "界首乡", value: ["118.794646", "29.544071", 2] },
      //   { name: "富文乡", value: ["119.215732", "29.646934", 1] },
      //   { name: "金峰乡", value: ["118.917690", "29.677469", 1] },
      // ],
      mapdot: [
        // {
        //   name: "百泉街道",
        //   value: ["115.99186545899967", "40.4474380075129", 27],
        // },
        // {
        //   name: "香水园街道",
        //   value: ["115.97976939384849", "40.456585210710024", 24],
        // },
        // { name: "儒林街道", value: ["115.9706277", "40.455959", 18] },
        { name: "延庆镇", value: ["115.984558", "40.458801", 17] },
        { name: "康庄镇", value: ["115.900139", "40.382671", 12] },
        { name: "八达岭镇", value: ["115.9778983", "40.362141", 12] },
        { name: "永宁镇", value: ["116.16304", "40.529102", 11] },
        { name: "旧县镇", value: ["116.087158", "40.550522", 10] },
        { name: "张山营镇", value: ["115.865662", "40.482952", 9] },
        { name: "四海镇", value: ["116.413231", "40.531727", 8] },
        { name: "千家店镇", value: ["116.346672", "40.693748", 4] },
        { name: "沈家营镇", value: ["116.045464", "40.481972", 4] },
        { name: "大榆树镇", value: ["116.029251", "40.43581", 3] },
        { name: "井庄镇", value: ["116.093391", "40.471901", 3] },
        { name: "刘斌堡乡", value: ["116.2260281", "40.56797", 3] },
        { name: "大庄科乡", value: ["116.235458", "40.431942", 2] },
        { name: "香营乡", value: ["116.145424", "40.571152", 2] },
        { name: "珍珠泉乡", value: ["116.425247", "40.560169", 2] },
        // { name: "富文乡", value: ["119.215732", "29.646934", 1] },
        // { name: "金峰乡", value: ["118.917690", "29.677469", 1] },
      ],
    }
  },
  components: {},
  mounted() {
    this.showMap()
  },
  methods: {
    showMap() {
      let mapChart = this.$echarts.init(document.getElementById("mapc"))
      // $.get("chunan.json").then(res => {
      //   console.log(res)
      // })
      // $.get(chunan, function (data) {
      //   console.log(data)
      // })
      this.$echarts.registerMap("chunan", yanqingData, {})
      let option = {
        title: {
          text: "",
          left: "center",
          textStyle: {
            color: "#ffffff",
          },
          top: 8,
        },
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
          // formatter: function (params) {
          // console.log('---sss--', params)
          // var value = (params.value + '').split('.');
          // value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, '$1,');
          // // return params.seriesName + '<br/>' + params.name + ': ' + value;
          // if (params.seriesName == 'line') {
          //   return params.seriesName;
          // } else {
          //   return params.seriesName + '：' + params.name;
          // }
          // }
        },
        visualMap: {
          min: 0,
          max: 330,
          splitNumber: 11,
          show: false,
          color: ["#ffb206"],
          // rgb(124, 231, 253)
          textStyle: {
            color: "#ffffff",
          },
        },
        geo: {
          map: "chunan",
          zoom: 1.2,
          // center: [120.509062, 23.564332],
          roam: false,
          // selectedMode: false,
          label: {
            show: false,
            emphasis: {
              show: false,
              textStyle: {
                color: "#ffffff",
              },
            },
            textStyle: {
              color: "#ffffff",
            },
          },
          itemStyle: {
            normal: {
              borderColor: "rgba(255,209,163, .5)", //区域边框颜色
              areaColor: "rgba(73,86,166,.1)", //区域颜色
              borderWidth: 0.5, //区域边框宽度
              shadowBlur: 5,
              shadowColor: "rgba(107,91,237,.7)",
            },
            emphasis: {
              borderColor: "rgba(255,209,163, .5)", //区域边框颜色
              areaColor: "rgba(73,86,166,.1)", //区域颜色
              borderWidth: 0.5, //区域边框宽度
              shadowBlur: 5,
              shadowColor: "rgba(107,91,237,.7)",
              // borderColor: '#FFD1A3',
              // areaColor: 'rgba(102,105,240,.3)',
              // borderWidth: 1,
              // shadowBlur: 5,
              // shadowColor: 'rgba(135,138,255,.5)',
            },
          },
        },
        series: [
          {
            name: "延庆区",
            type: "effectScatter",
            coordinateSystem: "geo",
            symbolSize: 15,
            encode: {
              value: 2,
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
            },
            label: {
              formatter: "{b}",
              position: "bottom",
              show: true,
            },
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "#333",
            },
            emphasis: {
              scale: true,
            },
            zlevel: 1,
            data: this.mapdot,
            symbol: "pin",
          },
        ],
      }
      mapChart.setOption(option)

      var index = 0 //播放所在下标
      this.mTime = setInterval(function() {
        mapChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: index,
        })
        index++
        if (index >= option.series[0].data.length) {
          index = 0
        }
      }, 3000)
      window.addEventListener("resize", function() {
        mapChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#centerTop {
  $box-height: 610px;
  $box-width: 100%;
  padding: 5px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .ct-total {
    width: 100%;
    padding-top: 10px;
    height: 15%;
    display: flex;
    justify-content: space-around;
    .ct-item {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 30%;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      // border: 1px solid #343f4b;
      // background-color: rgba(19, 25, 47, 0.8);
      font-size: 20px;
      color: rgb(158, 191, 232);
      .num {
        font-size: 34px;
        margin-top: 15px;
        color: #fff;
      }
    }
  }
}
</style>
