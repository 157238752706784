<template>
  <div id="leftBottom">
    <div class="lb-title">2022年4月残疾人就业出勤情况</div>
    <dv-scroll-board class="lb-content"
                     :config="config" />
  </div>
</template>

<script>

  export default {
    data() {
      return {
        config: {
          header: ['乡 / 镇', '出勤人数'],
          // data: [
          //   [
          //     "浙江省淳安县千岛湖镇",
          //     27
          //   ],
          //   [
          //     "浙江省淳安县里商乡",
          //     24
          //   ],
          //   [
          //     "浙江省淳安县文昌镇",
          //     18
          //   ],
          //   [
          //     "浙江省淳安县瑶山乡",
          //     17
          //   ],
          //   [
          //     "浙江省淳安县浪川乡",
          //     12
          //   ],
          //   [
          //     "浙江省淳安县梓桐镇",
          //     12
          //   ],
          //   [
          //     "浙江省淳安县威坪镇",
          //     11
          //   ],
          //   [
          //     "浙江省淳安县姜家镇",
          //     10
          //   ],
          //   [
          //     "浙江省淳安县枫树岭镇",
          //     9
          //   ],
          //   [
          //     "浙江省淳安县大墅镇",
          //     8
          //   ],
          //   [
          //     "浙江省淳安县临岐镇",
          //     4
          //   ],
          //   [
          //     "浙江省淳安县左口乡",
          //     4
          //   ],
          //   [
          //     "浙江省淳安县中洲镇",
          //     3
          //   ],
          //   [
          //     "浙江省淳安县屏门乡",
          //     3
          //   ],
          //   [
          //     "浙江省淳安县王阜乡",
          //     3
          //   ],
          //   [
          //     "浙江省淳安县安阳乡",
          //     2
          //   ],
          //   [
          //     "浙江省淳安县汾口镇",
          //     2
          //   ],
          //   [
          //     "浙江省淳安县界首乡",
          //     2
          //   ],
          //   [
          //     "浙江省淳安县富文乡",
          //     1
          //   ],
          //   [
          //     "浙江省淳安县金峰乡",
          //     1
          //   ]
          // ],
          data: [
            [
              "延庆区延庆镇",
              27
            ],
            [
              "延庆区康庄镇",
              24
            ],
            [
              "延庆区八达岭镇",
              18
            ],
            [
              "延庆区永宁镇",
              17
            ],
            [
              "延庆区旧县镇",
              12
            ],
            [
              "延庆区张山营镇",
              12
            ],
            [
              "延庆区四海镇",
              11
            ],
            [
              "延庆区千家店镇",
              10
            ],
            [
              "延庆区沈家营镇",
              9
            ],
            [
              "延庆区大榆树镇",
              8
            ],
            [
              "延庆区井庄镇",
              4
            ],
            [
              "延庆区刘斌堡乡",
              4
            ],
            [
              "延庆区大庄科乡",
              3
            ],
            [
              "延庆区香营乡",
              3
            ],
            [
              "延庆区珍珠泉乡",
              3
            ],
            // [
            //   "浙江省淳安县安阳乡",
            //   2
            // ],
            // [
            //   "浙江省淳安县汾口镇",
            //   2
            // ],
            // [
            //   "浙江省淳安县界首乡",
            //   2
            // ],
            // [
            //   "浙江省淳安县富文乡",
            //   1
            // ],
            // [
            //   "浙江省淳安县金峰乡",
            //   1
            // ]
          ],
          rowNum: 8, //表格行数
          headerHeight: 50,
          headerBGC: '#0f1325', //表头
          oddRowBGC: '#0f1325', //奇数行
          evenRowBGC: '#171c33', //偶数行
          index: true,
          columnWidth: [100, 180, 150],
          align: ['center']
        }
      }
    },
    components: {

    },
    mounted() {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  $box-width: 100%;
  $box-height: 480px;

  #leftBottom {
    padding: 20px;
    height: $box-height;
    width: $box-width;
    border-radius: 10px;
    .lb-title {
      width: 100%;
      height: 8%;
      // display: flex;
      // align-items: center;
      font-size: 18px;
      font-weight: 500;
      color: rgb(158, 191, 232);
    }
    .lb-content {
      width: 100%;
      height: 92%;
    }
  }
</style>
